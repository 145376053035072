<template>
  <IndexHeader :isActive="2"></IndexHeader>
  <div class="page-pricing">
    <!-- 主体区域 -->
    <div class="nav">
      <div class="section-plan">
        <div class="section-header">
          <h1 class="title">按需付费，一切为您！</h1>
          <p class="desc">了解权益明细 灵活定制云采集平台建设服务</p>
        </div>
        <!-- 版本服务表-->
        <table class="version-header">
          <tr class="thead">
            <td>
              <span class="title">服务说明</span>
              <br />
              <span class="desc">支付后可开发票</span>
            </td>
            <td>
              <span class="title">
                <i class="iconfont iconhuiyuan" />
                体验版
              </span>
              <br />
              <span class="tip">免费</span>
              <br />
              <el-button class="basic-buy" type="info" plain @click="goIndex">
                立即体验
              </el-button>
            </td>
            <td>
              <!-- <el-tag class="tag" type="warning" size="small">
                学生认证，享一折购
              </el-tag> -->
              <span class="title">
                <i class="iconfont iconhuiyuan standard-icon" />
                标准版
              </span>
              <!-- <el-tooltip placement="top-start" effect="dark" :offset="3">
                <template #content>
                  <div style="font-family: SourceHanSansSC-regular">
                    支持团队功能，可管理分配多账号
                  </div>
                </template>
                <i class="iconfont icon-tuandui" />
              </el-tooltip> -->
              <br />
              <span class="tip">
                <span class="price">￥1980</span>
                /年
              </span>
              <br />
              <!-- <span
                class="tip"
                style="font-size: 12px; text-decoration: line-through"
              >
                ￥980/年
              </span>
              <br /> -->
              <el-button
                class="senior-buy"
                type="warning"
                plain
                @click="handleOpenPurchase('standard')"
              >
                立即购买
              </el-button>
            </td>
            <td>
              <span class="title">
                <img src="@/assets/imgs/svip.png" class="premium-icon" />
                高级版
              </span>
              <br />
              <span class="tip">
                <span class="price">￥2980</span>
                /年
              </span>
              <br />
              <!-- <span
                class="tip"
                style="font-size: 12px; text-decoration: line-through"
              >
                ￥980/年
              </span>
              <br /> -->
              <el-button
                class="senior-buy"
                type="warning"
                plain
                @click="handleOpenPurchase('premium')"
              >
                立即购买
              </el-button>
            </td>
            <td>
              <span class="title">
                <img src="@/assets/imgs/svip.png" class="premium-icon" />

                机构版
              </span>
              <el-tooltip placement="top-start" effect="dark" :offset="3">
                <template #content>
                  <div style="font-family: SourceHanSansSC-regular">
                    支持机构版本，更加方便
                    <br />
                    管理机构用户
                  </div>
                </template>
                <i class="iconfont icon-bank" />
              </el-tooltip>
              <br />
              <span class="tip">按坐席数量，定制价格</span>
              <br />
              <el-button
                class="flagship-buy"
                type="primary"
                plain
                @click="handleOpenQRCode"
              >
                立即咨询
              </el-button>
            </td>
          </tr>
        </table>
        <table class="version">
          <tr>
            <td>采集数量限制</td>
            <td>500条/项目</td>
            <td>20w条/项目</td>
            <td>50w条/项目</td>
            <td>不限</td>
          </tr>
          <tr>
            <td>免费爬虫模板数量</td>
            <td>1个</td>
            <td>10个</td>
            <td>20个</td>
            <td>不限</td>
          </tr>
          <tr>
            <td>数据储存时间</td>
            <td>3个月</td>
            <td>有效期内永久</td>
            <td>有效期内永久</td>
            <td>永久</td>
          </tr>
          <tr>
            <td>储存空间</td>
            <td>1G</td>
            <td>30G</td>
            <td>100G</td>
            <td>不限</td>
          </tr>
          <tr>
            <td>采集任务并发数</td>
            <td>1个</td>
            <td>5个</td>
            <td>50个</td>
            <td>不限</td>
          </tr>
          <tr>
            <td>采集任务优先级</td>
            <td>低</td>
            <td>中</td>
            <td>高</td>
            <td>最高</td>
          </tr>
          <tr>
            <td>采集结果导出</td>
            <td><i class="iconfont icon-duigouxiao" /></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
          </tr>
          <tr>
            <td>采集执行策略</td>
            <td></td>
            <td></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
          </tr>
          <tr>
            <td>分布式集群采集</td>
            <td></td>
            <td></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
          </tr>
          <tr>
            <td>私有云部署</td>
            <td></td>
            <td></td>
            <td></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
          </tr>
          <!-- <tr>
            <td>AI生成编码</td>
            <td></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
          </tr>
          <tr>
            <td>AI编码</td>
            <td></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
          </tr>
          <tr>
            <td>概念结构</td>
            <td></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
          </tr>
          <tr>
            <td>AI归纳概念</td>
            <td></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
          </tr>
          <tr>
            <td>关系分析</td>
            <td></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
          </tr>
          <tr>
            <td>词频统计</td>
            <td></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
          </tr>
          <tr>
            <td>私有云部署</td>
            <td></td>
            <td></td>
            <td></td>
            <td><i class="iconfont icon-duigouxiao" /></td>
          </tr>

          <tr>
            <td>Tokens</td>
            <td>
              1万个Token
              <el-tooltip content="锐研新用户可免费获得1万个Token">
                <el-icon size="11px"><Warning /></el-icon>
              </el-tooltip>
            </td>
            <td>30万个Token</td>
            <td>100万个Token</td>
            <td>无限</td>
          </tr> -->
        </table>
      </div>
    </div>

    <!-- 底部 -->
    <div class="footer">
      <IndexFooter></IndexFooter>
    </div>
    <el-dialog
      v-model="QRCodeDLGVisible"
      title="立即咨询"
      width="25%"
      :before-close="handleCloseQRCodeDLG"
    >
      <div class="QRCode-dialog">
        <div class="QRCode-tip">扫描添加企业微信客服</div>
        <img class="QRCode-img" src="@/assets/imgs/QR-code.png" />
        <div class="QRCode-tip">或致电</div>
        <div class="QRCode-phone">4006-213-056</div>
        <!-- <el-button class="QRCode-button" @click="goBuy">更多联系方式</el-button> -->
      </div>
    </el-dialog>
    <!-- <PurchaseDialog ref="dialogPurchaseRef" /> -->
  </div>
</template>
<script setup>
import { onMounted, defineProps, ref, nextTick, computed } from "vue";
import IndexHeader from "@/components/layout/IndexHeader.vue";
import IndexFooter from "@/components/layout/IndexFoot.vue";
// import PurchaseDialog from "@/views/system/components/PurchaseDialog.vue";
import { useRoute, useRouter } from "vue-router";
import { tr } from "element-plus/es/locale";
import VueCookies from "vue-cookies";
import { goLogin } from "@/utils/login";
import { checkUserLoginStatus } from "@/utils/utils.js";

const route = useRoute();
const router = useRouter();
const goIndex = () => {
  router.push("/");
};
// const dialogPurchaseRef = ref();
const handleOpenPurchase = (type) => {
  if (checkUserLoginStatus()) {
    // dialogPurchaseRef.value.openDialogPurchase(type);
    if (type === "standard") {
      window.open("https://www.ringdata.com/createorder?st=19");
    } else if (type === "premium") {
      window.open("https://www.ringdata.com/createorder?st=20");
    }
  } else {
    goLogin(window.location.href);
  }
  // window.open("https://www.ringdata.com/prices", "_blank");
};
const QRCodeDLGVisible = ref(false);
const handleOpenQRCode = () => {
  QRCodeDLGVisible.value = true;
};
const handleCloseQRCodeDLG = () => {
  QRCodeDLGVisible.value = false;
};
</script>
<style lang="scss" scoped>
.page-pricing {
  // 版本价格表格start
  .nav {
    padding: 120px 0px 10px;
    max-width: 1200px;
    margin: 0 auto;
    font-weight: 300 !important;
    color: #666;
    .section-plan {
      margin-left: 8px;
      margin-right: 8px;

      .section-header {
        width: 100%;
        height: 120px;
        overflow: hidden;
        background-color: #fff;
        z-index: 10;
        padding-bottom: 10px;
        h1 {
          margin-bottom: 40px;
        }
        h1.title {
          background: #fff;
          text-align: center;
          color: #000;
          font-size: 34px;
          font-weight: 500;
          margin-top: 0;
          margin-bottom: 30px;
          font-family: SourceHanSansSC-regular;
        }
        p.desc {
          text-align: center;
          font-weight: 300;
          padding: 10px;
          color: rgba(108, 108, 108, 1);
          font-size: 14px;
          font-family: SourceHanSansSC-regular;
        }
      }
      .version-header {
        width: 1200px;
        text-align: center;
        position: sticky;
        top: 60px;
        z-index: 20;
        background-color: #fff;
        line-height: 25px;
        .thead {
          text-align: center !important;
          td {
            padding: 18px 0px;
            border: 1px solid #efefef;
            position: relative;
            .tag {
              position: absolute;
              top: 0;
              left: 0;
              font-size: 10px;
              border-radius: 5px 2px 16px 1px;
              background: linear-gradient(
                91.49deg,
                rgba(255, 46, 74, 1) 0.98%,
                rgba(253, 129, 16, 1) 98.21%
              );
              color: rgba(16, 16, 16, 1);
              border: 0px;
              color: rgba(255, 255, 255, 1);
              padding: 5px;
              font-family: SourceHanSansSC-regular;
            }
          }
          .title {
            color: rgba(16, 16, 16, 1);
            font-size: 16px;
            text-align: left;
            font-family: SourceHanSansSC-regular;
            font-weight: 500;
            line-height: 20px;
            .iconfont {
              font-size: 22px;
              color: #bcbcbc;
              margin-right: 5px;
            }
            .standard-icon {
              color: #ffc957;
            }
            .premium-icon {
              width: 24px;
              width: 24px;
              display: inline-block;
              vertical-align: middle;
              margin-right: 5px;
            }
          }
          .tip {
            color: #9b9b9b;
            font-size: 13px;
            font-family: SourceHanSansSC-regular;
            font-weight: 500;
            line-height: 20px;
            .price {
              font-size: 16px;
              color: $theme-color;
              font-weight: 550;
            }
          }
          .desc {
            color: rgba(154, 154, 154, 1);
            font-size: 12px;
            text-align: left;
            font-family: SourceHanSansSC-regular;
            line-height: 20px;
          }
          .iconfont {
            margin-left: 10px;
            color: rgba(26, 120, 255, 1);
            font-size: 12px;
            position: inline-block;
            vertical-align: middle;
          }
          .basic-buy {
            font-size: 12px;
            height: 25px;
            background-color: #ffffff;
            border-color: rgba(154, 154, 154, 1);
            color: rgba(154, 154, 154, 1);
            font-family: SourceHanSansSC-regular;
            margin-top: 10px;
          }
          .senior-buy {
            font-size: 12px;
            height: 25px;
            background-color: #ffffff;
            border-color: $theme-color;
            color: $theme-color;
            font-family: SourceHanSansSC-regular;
            margin-top: 10px;
          }
          .flagship-buy {
            font-size: 12px;
            height: 25px;
            background-color: #ffffff;
            border-color: $theme-color;
            color: $theme-color;
            font-family: SourceHanSansSC-regular;
            margin-top: 10px;
          }
        }
        tr td {
          width: 240px;
        }
      }
      .version {
        width: 1200px;
        height: 240px;
        text-align: center;
        line-height: 40px;
        border: 1px solid #efefef;
        border-top: 0px;
        border-collapse: collapse; /* 合并单元格 */

        tr {
          .iconfont {
            color: $theme-color;
            display: inline-block;
            vertical-align: middle;
          }
          .icon-wenhao {
            position: absolute;
            color: #000;
            font-size: 12px;
            font-weight: bold;
            margin-left: 10px;
            vertical-align: middle;
          }
        }
        .tr-title {
          border: 1px solid #efefef;
          .td-bold {
            font-weight: bold;
            color: #000;
          }
        }
        tr:nth-child(1) {
          border-top: 0px;
        }
        tr:nth-child(odd) {
          background-color: rgba(127, 68, 240, 0.03);
        }
        tr td {
          width: 240px;
        }
      }
    }
  }

  // 底部
  .footer {
    padding: 30px 10px;
    text-align: center;
    background: #fff;
  }
}
//二维码弹框
.QRCode-dialog {
  padding: 20px;
  text-align: center;
  .QRCode-tip {
    color: #585858;
    margin-bottom: 10px;
  }
  .QRCode-img {
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
  }
  .QRCode-phone {
    color: #585858;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .QRCode-button {
    border-color: $theme-color;
    color: $theme-color;
  }
}
//手机自适应
@media screen and (max-width: 767px) {
  .page-pricing {
    // 版本价格表格start
    // 主体
    .nav {
      padding: 110px 0px 10px;

      .section-plan {
        .section-header {
          margin-bottom: 10px;
          h1.title {
            font-size: 25px;
          }
        }
        .version-header {
          width: 500px;

          .thead {
            .title {
              font-size: 14px;
            }
          }
          tr td {
            width: 125px;
          }
        }
        .version {
          width: 500px;

          tr td {
            width: 125px;
          }
        }
      }
    }
  }
}
</style>
